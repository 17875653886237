<template>
  <div>
    <v-col cols="12">
      <v-form ref="form" v-model="valid" class="pa-3">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Фамилия"
              :value="value.LastName"
              @input="update('LastName', $event)"
              required
              :rules="lnRule"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Имя"
              :value="value.FirstName"
              @input="update('FirstName', $event)"
              required
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Отчество"
              :value="value.MiddleName"
              @input="update('MiddleName', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <custom-date-picker
              text="Дата рождения"
              v-model="value.Birthdate"
              outlined
            >
            </custom-date-picker>
          </v-col>
          <v-col>
            <v-select
              outlined
              color="orange"
              label="Пол"
              :items="genders"
              :value="value.Sex"
              @input="update('Sex', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              outlined
              color="orange"
              label="Категория"
              :items="categories"
              :value="value.Category.Text"
              @input="update('Category', $event)"
              :rules="requiredRule"
              :disabled="true"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6">
            <v-select
              v-model="docType"
              ref="Doccode"
              outlined
              color="orange"
              label="Тип документа"
              :items="docs"
              :value="value.DocType"
              @input="update('DocType', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Выбранные места"
              :value= "'Место:' + value.Car.place + '   Вагон:' + value.Car.vagon + '   Тип вагона:' +  value.Car.carTypeName + '   Цена:' + value.Car.price "
              :disabled="true"
              required
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              outlined
              color="orange"
              label="Гражданство"
              :items="countriesList"
              :value="value.Nationality"
              @input="update('Nationality', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Номер документа"
              :value="value.Doc"
              @input="update('Doc', $event)"
              required
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
          <v-col>
            <custom-date-picker
              label="Срок действия документа"
              v-model="value.Pspexpire"
              outlined
              :disabled="!checkDoccode"
            >
            </custom-date-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </div>
</template>

<script>
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";
import DateFormat from "@/mixins/DateFormat";
import Validators from "@/mixins/Validators";

export default {
  components: { CustomDatePicker },
  name: "RailwayPassengerInfo",
  mixins: [Validators, DateFormat],
  data() {
    return {
      menu: [],
      valid: false,
      genders: [
        { text: "Мужской", value: "Male" },
        { text: "Женский", value: "Female" },
      ],
      categories: [
        { text: "Взрослый", value: "Adult" },
        { text: "Ребенок", value: "Child" },
        { text: "Младенец без места", value: "BabyWithoutPlace" },
      ],
      maxDate: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
      docType: "RussianPassport",
      carsDisplay: [],
    };
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    countriesList: {
      type: Array,
      default: [],
    },
    docs: {
      type: Array,
      default: [],
    },
    cars: {
      type: Array,
      require: true,
    },
    passengersSeats: {
      type: Array,
      require: true,
    }
  },
  methods: {
    update(key, value) {
      switch (key) {
        case "Pspexpire":
          this.$emit("input", { ...this.value, [key]: this.formatDate(value) });
          break;
        case "Birthdate":
          this.$emit("input", { ...this.value, [key]: this.formatDate(value) });
          break;
        case "Category":
          this.$emit("input", {
            ...this.value,
            [key]: { Text: value, Rbm: null },
          });
          break;
        default:
          this.$emit("input", { ...this.value, [key]: value });
          break;
      }
    },
    updateV() {
      this.$emit("updateValid", this.valid);
    },
  },
  watch: {
    valid: function () {
      this.updateV();
    },
  },
  computed: {
    checkDoccode() {
      let a =
        this.docs.find((e) => e.value === this.docType) !== undefined
          ? this.docs.find((e) => e.value === this.docType).haveValidTill
          : 0;
      this.value.Pspexpire = a ? this.value.Pspexpire ?? "" : null;
      return a;
    },
  },
  mounted() {
    for (var car of this.cars) {
      var part =
        this.value.Category.Text == "Adult"
          ? 1
          : this.value.Category.Text == "Child"
          ? 0.7
          : 0;
      this.carsDisplay.push({
        text:
          "Номер места: " +
          value.Place.place +
          "Номер вагона: " +
          value.Place.vagon +
          ", Тип вагона: " +
          car.carTypeName +
          ", Цена: " +
          value.Place.price+
          " руб, Свободно: " +
          car.placeQuantity,
        value: { carNumber:car.carNumber,
        schemeId:car.railwayCarSchemeId},
        carType: car.carType,
        price: car.maxPrice * part,
        serviceClass: car.serviceClass ?? null,
      });
    }
  },
};
</script>

<style></style>

<template>
  <v-row :id="id">
    <v-col>
      <v-hover v-slot="{ hover }">
        <v-card

          :elevation="hover ? 4 : 1"
          class="px-5 my-5 rounded-card"
          :class="colorIndex % 2 === 0 ? 'item1' : 'item2'"
        >

          <v-fade-transition>
            <v-row v-show="animation">
              <v-col style="margin-right: 60px" class="mt-4">
                <v-row class="mt-2 mt-md-0">
                  <v-chip class="on-border-left-category ml-12 text-h5 px-5 py-5" color="green">
                    {{ variant.trainCategory }}
                  </v-chip>
                  <v-chip class="on-border-left mr-12 text-h5 px-5 py-5" color="green">
                    {{ variant.trainNumber }}
                  </v-chip>

                  <v-col cols="12" class="d-block d-md-none text-center pb-0">
                    <v-chip
                      color="green accent-4"
                      text-color="white"
                      class="mb-1"
                      style="font-weight: bold; font-size: 15px"
                    >
                      {{
                        variant.passengerDepartureStationName
                      }}&nbsp;&nbsp;-&nbsp;&nbsp;{{ variant.passengerArrivalStationName }}
                    </v-chip>
                  </v-col>
                  <v-col cols="6" class="d-block d-md-none text-center pt-1">
                    <v-chip large>
                      <v-icon class="mr-1">mdi-clock-time-ten-outline</v-icon>
                      <span>{{
                          variant.tripDuration +
                        "ч " +
                          variant.tripDuration +
                        "м"
                      }}</span>
                    </v-chip>
                  </v-col>

                  <v-col cols="6" md="4" ml="4" class="text-center mt-2">
                    <v-icon class="mt-4" large>mdi-train</v-icon>
                    <div class="text-h4 text-md-h6">
                      {{ variant.departureStationName/*.split("T")[1].slice(0, 5) */ }}
                      <div>
                      </div>
                    </div>
                    <v-chip class="d-none d-md-block wrap responsive-text">
                      <v-icon class="mr-1">mdi-map-marker</v-icon>
                      {{ variant.passengerDepartureStationName }}
                    </v-chip>
                    <div class="d-none d-md-block">
                      {{ formatDate(variant.departureDateTime) }}
                    </div>
                  </v-col>

                  <v-col class="d-none d-sm-block d-md-none">
                    <v-row justify="center" align="center">
                      <arrow></arrow>
                    </v-row>
                  </v-col>
                  <v-col md="2" class="text-center d-none d-md-block">
                    <div class="mb-3 mb-md-1">
                      {{ Math.floor(variant.tripDuration / 60) + "ч " + (variant.tripDuration % 60) + "м" }}
                    </div>

                    <v-row justify="center" align="center">
                      <arrow></arrow>
                    </v-row>
                  </v-col>

                  <v-col cols="6" md="4" class="text-center">
                    <v-icon class="mt-6" large>mdi-train</v-icon>
                    <div class="text-h4 text-md-h6">
                      {{ variant.arrivalStationName/*.split("T")[1].slice(0, 5) */ }}
                      <div>
                      </div>
                    </div>
                    <v-chip class="d-none d-md-block pa-2 wrap-text responsive-text custom-chip-padding">
                      <v-icon class="mr-1">mdi-map-marker</v-icon>
                      {{ variant.passengerArrivalStationName }}
                    </v-chip>
                    <div class="d-none d-md-block">
                      {{ formatDate(variant.arrivalDateTime) }}
                    </div>
                  </v-col>

                  <v-col cols="12" class="d-block d-md-none text-center py-0">
                    <v-row justify="center">
                      <v-col class="text-center">
                        <v-divider></v-divider>
                        {{ variant.arrivalStationName }}
                      </v-col>
                      <v-col class="text-center">
                        <v-divider></v-divider>
                        {{ variant.departureStationName }}
                      </v-col>

                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" sm="3">
                <div class="title mt-6">В наличии:</div>
                <v-row no-gutters v-for="carGroup in carGroups" :key="carGroup.id" class="my-1">
                  <v-col cols="12" sm="12">
                    <info-chip style="margin-right: 10px" :title="carGroup.carTypeName"
                               :value="carGroup.minTicketPrice+ ' ₽' "
                               :value2="carGroup.totalPlaceQuantity + ' мест'"></info-chip>
                    <!--                    <info-chip title="Мин цена: " :value="carGroup.minTicketPrice + '₽'"></info-chip>-->
                    <!--                    <v-chip style="width: 150px" class="ma-2" color="green" text-color="white">
                                          {{ carGroup.carTypeName }}: {{ carGroup.totalPlaceQuantity }} мест
                                        </v-chip>-->
                  </v-col>
                  <!--                  <v-col cols="6">
                                      <v-chip style="width: 150px" class="ma-2" color="orange" text-color="white">
                                        ОТ {{ carGroup.minTicketPrice }} ₽
                                      </v-chip>
                                    </v-col>-->
                </v-row>
              </v-col>
            </v-row>
          </v-fade-transition>
          <v-divider class="my-2"></v-divider>
          <v-row
            no-gutters
            justify="space-between"
            align="center"
            class="pb-2 pt-2 pt-md-0"
          >
            <!--
                        <v-col cols="5" class="d-block d-md-none pb-5">
                          В наличии:
                          <span class="green&#45;&#45;text text&#45;&#45;accent-4">
                            {{ variant.placeQuantity }}
                          </span>
                        </v-col>
            -->

            <!--            <v-col
                          cols="7"
                          class="text-h5 d-block d-md-none pb-5 text-right"
                          style="font-weight: bold"
                        >
                          {{ variant.carGroups + " – " + variant.maxPrice + " РУБ" }} &lt;!&ndash;Todo:Сделать сумму по вагонам (Высчитывать ее)&ndash;&gt;
                        </v-col>-->
            <info-chip class="on-border-right" title="Время до отправления: "
                       :value="remainingTime.hours + ' часов ' + remainingTime.minutes + ' минут'"
                       v-if="isLessThanSixHours"></info-chip>
            <v-col cols="12" md="12" class="text-right">
              <v-btn
                  dark
                  elevation="0"
                  color="green"
                  @click="$emit('BookTrain', variant)"
              >
                Выбрать от {{ Math.min(...carGroups.map(group => group.minTicketPrice)) }} ₽
              </v-btn>

              <v-row align="center">

                <v-col cols="6" md="3" lg="2" class="align-end">
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-hover>
    </v-col>
    <error-modal v-model="hasErrors" :error="error"></error-modal>
  </v-row>
</template>

<script>
import Arrow from "@/components/UI/Arrow.vue";
import ApiCalls from "@/mixins/booking/ApiCalls";
import ErrorModal from "@/components/UI/ErrorModal";
import variantListItem from "./VariantListItem.vue";
import InfoChip from "@/components/UI/InfoChip.vue";
import moment from "moment";
import {format} from "date-fns";

export default {
  name: "TrainListItem",
  mixins: [ApiCalls],
  props: {
    colorIndex: {
      type: Number,
      default: 0,
    },
    variant: {
      type: Object,
      require: true,
    },
    allowedDocs: {
      type: Array,
      require: true,
    },
    passengers: {
      type: Array,
      require: true,
    },
    depCity: {
      type: String,
      require: true,
    },
    arrCity: {
      type: String,
      require: true,
    },
  },
  components: {
    InfoChip,
    Arrow,
    ErrorModal,
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      const hours = date.getHours().toString().padStart(2, '0');
      const minutes = date.getMinutes().toString().padStart(2, '0');
      return `${day}.${month}.${year} ${hours}:${minutes}`;
    },
  },
  data() {
    return {
      animation: true,
      id: null,
      hasErrors: false,
      error: { Code: "", Message: "" },
    };
  },
  mounted() {
    this.id = "var_" + this._uid;
  },
  computed: {
    isLessThanSixHours() {
      const now = moment();
      const departureTime = moment(this.variant.departureDateTime);
      const diff = departureTime.diff(now, 'hours');
      return diff < 6;
    },
    remainingTimeColor() {
      const now = moment();
      const departureTime = moment(this.variant.departureDateTime);
      const diff = departureTime.diff(now, 'minutes');
      const percentage = Math.max(0, Math.min(1, diff / (60 * 15)));
      const red = Math.floor(255 * (1 - percentage));
      const green = Math.floor(255 * percentage);
      return `rgb(${red}, ${green}, 0)`;
    },
    remainingTime() {
      const now = moment();
      const departureTime = moment(this.variant.departureDateTime);
      const diff = departureTime.diff(now, 'minutes');
      const days = Math.floor(diff / (60 * 24));
      const hours = Math.floor((diff % (60 * 24)) / 60);
      const minutes = diff % 60;
      return diff > 0 ? {days, hours, minutes} : {days: 0, hours: 0, minutes: 0};
    },
    hasGenderCabinsInAnyGroup() {
      return this.variant.carGroups.some(carGroup => carGroup.hasGenderCabins);
    },
    carGroups() {
      const groups = [];
      let groupprice = 0;
      for (const carGroup of this.variant.carGroups) {
        groups.push(carGroup);
      }
      return groups;
    }
  },
};
console.log(variantListItem)
</script>
<style scoped>
.remaining-time {
  font-weight: bold;
  font-size: 1.2em;
  background-color: transparent !important;
}

.wrap-text {
  word-wrap: break-word;
}

.responsive-text {
  font-size: 100%;
}

Ц

@media (max-width: 600px) {
  .responsive-text {
    font-size: 80%;
  }
}

.on-border-right {
  position: absolute;
  top: -15px;
  right: 20px;
}

.on-border-left {
  position: absolute;
  top: -20px;
  left: 20px;
}

.on-border-left-category {
  position: absolute;
  top: -20px;
  left: 90px;
}


@media (max-width: 400px) {
  .responsive-text {
    font-size: 60%;
  }
}
.price {
  display: inline-block;
  font-weight: bold;
}

.rounded-card {
  border: solid 1px orange !important;
}

.custom-chip-padding {
  padding: 4px 8px;
}
</style>
